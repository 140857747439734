import { Component, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { UserService } from '../../../services/user.service';
import { environment } from '../../../../../environments/environment';
import { GlobalVariables } from '../../../../core/services/global-variables.service';

declare let grecaptcha;

@Component({
  selector: 'hos-new-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class NewForgotPasswordComponent implements AfterViewInit {
  @Output() hideForgotPassword = new EventEmitter();

  errorMessage: String = '';

  successMessage: String = '';

  title: string;

  message: string;

  email: string;

  isValidCaptcha: Boolean = true;

  showSubmit: Boolean = true;

  captchaResponse: String = '';

  email_regex: String;

  registerLink: Boolean = false;

  @Input() isRegisterSucess: boolean;

  /**
   *
   * Creates an instance of NewForgotPasswordComponent.
   *
   * @param {Router} router
   * @param {UserService} userService
   *
   * @memberof NewForgotPasswordComponent
   */
  constructor(
    public simpleModalService: SimpleModalService,
    private router: Router,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    private globalVariables: GlobalVariables,
  ) {
    this.email_regex = environment.email_regex;
    this.showSubmit = true;
  }

  ngAfterViewInit() {
    /* ReCaptcha render For Desktop & Mobile AP */
    grecaptcha.render(document.getElementById('captcha'), {
      sitekey: environment.Captcha.siteKey,
      theme: 'light',
      callback: this.verifyCallback.bind(this),
    });
    grecaptcha.render(document.getElementById('captchaMob'), {
      sitekey: environment.Captcha.siteKey,
      size: 'compact',
      theme: 'light',
      callback: this.verifyCallback.bind(this),
    });
    /* grecaptcha.render(document.getElementById('captchaMob'),
      { sitekey: environment.Captcha.siteKey, theme: 'light', 'callback': this.verifyCallback.bind(this) }); */
  }

  verifyCallback(data: String) {
    this.captchaResponse = data;
  }

  /**
   * Form submit handler
   *
   * @param {any} form
   * @param {Boolean} isValidEmail
   *
   * @memberof NewForgotPasswordComponent
   */
  onSubmit(form: any) {
    this.errorMessage = '';
    this.successMessage = '';
    if (form.valid) {
      this.userService.forgotPassword(form.value.email).subscribe(
        data => {
          if (data.status === 200) {
            this.successMessage =
              'An email will be sent to your account that will contain a link back to our site with further instructions. If you do not receive the email to your inbox, please be sure to check your spam folder as well.';
            this.showSubmit = false;
            this.registerLink = false;
          }
        },
        error => {
          if (error.status === 404) {
            this.registerLink = true;
            this.errorMessage = 'We do not have an account registered for that email address, please register a new account.';
          } else {
            this.errorMessage = error.error.message;
          }
        },
      );
    } else if (!form.value.email) {
      this.errorMessage = 'You need to enter your email.';
    } else {
      this.errorMessage = 'This is not a valid email.';
    }
  }

  onCaptchaSubmit() {
    if (this.captchaResponse) {
      this.userService.verifyCaptcha(this.captchaResponse).subscribe(
        () => {
          this.title = 'Forgot your password?';
          this.isValidCaptcha = true;
        },
        () => {
          this.title = 'Forgot your password?';
          this.isValidCaptcha = true;
        },
      );
    }
  }

  disableForgotPassword() {
    this.hideForgotPassword.emit();
  }

  onFocusChange() {
    this.errorMessage = '';
    this.successMessage = '';
  }

  goToRegister() {
    const { referer } = this.activatedRoute.snapshot.queryParams;
    if (referer) {
      this.globalVariables.routeReferer = decodeURIComponent(referer);
      this.router.navigate(['/user/register'], {
        queryParams: {
          referer: this.globalVariables.routeReferer,
        },
      });
    } else {
      this.router.navigate(['/user/register']);
    }
  }
}
