/* eslint-disable prefer-destructuring */
import { Component, OnInit, Input, AfterViewInit, PLATFORM_ID, Inject, HostListener } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Location, PlatformLocation, isPlatformBrowser } from '@angular/common';
import { find, forEach, join, uniq, flatten } from 'lodash-es';
import { SimpleModalService } from 'ngx-simple-modal';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ProductService } from '../../services/product.service';
import { Breadcrumb } from '../../../core/component/breadcrumb/breadcrumb.component';
import { SeoService } from '../../../core/services/seo.service';
import { SeoConfig } from '../../../core/model/seo-config.model';
import { environment } from '../../../../environments/environment';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
// import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { LocationService } from '../../../home/services/location.service';
import { SiteContentService } from '../../../core/services/siteContents';
import { ProviderModalComponent } from '../provider-modal/provider-modal.component';
import { UtilsService } from '../../../core/services/utils.service';
import { OrdersService } from '../../../payment-ets/services/orders.service';
import { UserService } from '../../../user/services/user.service';
import { ZipcodeService } from '../../../core/services/zipcode.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';

@Component({
  selector: 'hos-products',
  templateUrl: './products.component.html',
  styleUrls: ['products.component.scss'],
})
export class ProductsComponent implements OnInit, AfterViewInit {
  @Input() services: any;

  isPageNotFoundComp: Boolean = false;

  products: Array<any> = [];

  locData: any;

  types: Array<any> = [];

  categories: Array<any> = [];

  selectedAddress: any = {};

  selectedCity: any;

  filterArray: Array<any> = [];

  queryString: String = '';

  mapCategoriesById: any = {};

  mapTypesById: any = {};

  showLoader = true;

  partnerName: any;

  locationCode: any;

  locationCodeData: any;

  zipcode: any;

  total: number;

  limit: Number = 12;

  productServiceUrl: any;

  showProvider: Boolean = false;

  listingPage: Boolean = false;

  customer_service_number: String;

  user: any;

  fetchProducts: Boolean = false;

  paginatedProducts: Array<any> = [];

  breadcrumsConfig: Array<Breadcrumb> = [
    {
      title: 'Home',
      link: '/',
      active: false,
    },
    {
      title: 'Products',
      link: '',
      active: true,
    },
  ];

  siteContent: any;

  baseURL: String;

  providerBaseUrl: String;

  seoConfig: SeoConfig;

  bannetTitle: String;

  bannerImage: String;

  backPress: Boolean = false;

  userDetails: any;

  allStates: any;

  allowedTypes = ['residential', 'commercial'];

  categoryNameVal: any;

  logoURL: any;

  shareIconIndexArray: any = [];

  isbrowser: boolean = true;

  fixedHeader: Boolean = false;

  type: string;

  locCode: String;

  setOldPriceFlag: boolean = false;

  zipcodeLookupTexts: any;

  isZipcodeChangeClicked: boolean = false;

  isAsteriskPresent: boolean = false;

  constructor(
    public sessionStorageService: SessionStorageService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
    private locationGo: Location,
    private siteContentService: SiteContentService,
    private simpleModalService: SimpleModalService,
    private locationService: LocationService,
    public platformLocation: PlatformLocation,
    public utilsService: UtilsService,
    public globalVariables: GlobalVariables,
    private orderService: OrdersService,
    private userService: UserService,
    private zipcodeService: ZipcodeService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    this.isbrowser = isPlatformBrowser(this.platformId);
    platformLocation.onPopState(() => {
      this.backPress = true;
      let url = '';
      if (this.isbrowser) {
        url = window.location.pathname;
        window.location.href = url;
      }
    });
    this.baseURL = `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}`;
    this.utilsService.event.subscribe(eventData => {
      if (Object.keys(eventData).length !== 0) {
        this.utilsService.getPartnerDetails$().subscribe(
          data => {
            this.siteContent = data.body.site.homepage.safetySteps;
            this.zipcodeLookupTexts = data.body.site.zipcodeLookupText;
          },
          () => {},
        );
      }
    });
    this.siteContentService
      .statesdata$()
      .toPromise()
      .then(response => {
        this.allStates = response;
      });
    this.user = this.userService.getSignedInUser();
    this.logoURL = this.sessionStorageService.getItem('logoURL');
  }

  ngOnInit() {
    this.zipcode = this.route.snapshot.params.zipcode;
    this.type = this.route.snapshot.params.type;
    // this.locationCode = this.sessionStorageService.getItem('location_code');
    /* Check for Query Params Redirect - Start */
    if (this.route.snapshot.queryParams.zipcode) {
      if (this.zipcode === undefined) {
        this.zipcodeService.getZipcodeId(this.route.snapshot.queryParams.zipcode).subscribe(res => {
          let locationCodeId: any;
          let locationCodeData: any;
          if (res.body.locationCodeId) {
            if (this.route.snapshot.queryParams.location_code) {
              locationCodeId = this.route.snapshot.queryParams.location_code;
            } else {
              locationCodeId = res.body.locationCodeId;
            }
            this.productService.getProviderContent(locationCodeId).subscribe(data => {
              locationCodeData = data.body;
              this.router.navigate([
                `/products/${this.route.snapshot.queryParams.zipcode}/${locationCodeData.locationCode.toLowerCase()}/residential`,
              ]);
            });
          }
        });
      } else {
        this.isPageNotFoundComp = true;
        return;
      }
    }
    /* Check for Query Params Redirect - End */
    this.customer_service_number = environment.customer_service_number;
    this.productServiceUrl = environment.Image_API_Endpoint + environment.Product_service_API_Endpoint;
    this.providerBaseUrl = environment.Image_API_Endpoint + environment.Location_service_API_Endpoint; // not used in HTML
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    if (this.zipcode) {
      this.selectedAddress = {
        rawData: '',
        zipcode: this.zipcode,
      };
    } else if (savedAddress) {
      this.selectedAddress = JSON.parse(savedAddress);
    }
    this.userDetails = this.userService.getSignedInUser();
    this.types = [
      {
        id: 1,
        type: 'Residential',
      },
      {
        id: 3,
        type: 'Commercial',
      },
    ];
    this.mapTypesById = {
      1: {
        name: 'Residential',
      },
      3: {
        name: 'Commercial',
      },
    };
    this.filterArray = [
      {
        key: 'contact_type',
        value: 1,
      },
      {
        key: 'category_ids',
        value: null,
      },
      {
        key: 'program_ids',
        value: '',
      },
      {
        key: 'allProducts',
        value: true,
      },
      {
        key: 'isShowOnListing',
        value: true,
      },
    ];
    this.productService.categories$().subscribe(categories => {
      this.categories = categories.body;
      if (this.categories && this.categories.length > 0) {
        this.mapCategories(this.categories);
      }
      this.route.params.subscribe((queryParams: Params) => {
        const queryParam = this.route.snapshot.queryParams;
        if (
          environment.redirectDigitalPoc.locationCodeIds.includes(queryParams.locationcode) &&
          !this.userDetails &&
          !queryParam.isFromBundling &&
          this.isbrowser
        ) {
          window.location.href = environment.redirectDigitalPoc.redirectUrl + this.zipcode;
        }
        if (queryParams.category) {
          if (this.categories.length > 0) {
            this.fetchProducts = false;
            const setCategoryParamsFilter = this.categories.filter(
              item => item.categoryName.toString().split(' ').join('-').split(',').join('').toLowerCase() === queryParams.category.toLowerCase(),
            );
            if (setCategoryParamsFilter.length > 0) {
              this.filterArray[1].value = setCategoryParamsFilter[0]._id;
            } else {
              this.isPageNotFoundComp = true;
            }
          }
        }
      });
    });
    const state = this.sessionStorageService.getItem('providerState');
    if (!state) {
      this.productService.getProductListContent().subscribe(resData => {
        const data = resData.body;
        this.bannetTitle = data[0].header.title;
        // tslint:disable-next-line:max-line-length
        this.bannerImage = this.utilsService.buildImageUrl(
          `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}/${data[0].header.bannerImage.url}`,
        );
      });
    } else {
      this.utilsService.event.subscribe(eventData => {
        if (Object.keys(eventData).length !== 0) {
          this.utilsService.getPartnerDetails$().subscribe(
            data => {
              const siteData = data.body.site.productpage.header;
              this.bannetTitle = siteData.title;
              // tslint:disable-next-line:max-line-length
              this.bannerImage = this.utilsService.buildImageUrl(
                `${environment.Image_API_Endpoint}${environment.Content_service_API_Endpoint}/${siteData.bannerImage.url}`,
              );
            },
            () => {},
          );
        }
      });
    }
    this.route.params.subscribe((queryParams: Params) => {
      if (this.isbrowser) {
        window.scrollTo(0, 0);
      }
      if (queryParams.type) {
        if (this.allowedTypes.includes(queryParams.type.toLowerCase())) {
          const setTypeParamsFilter = environment.types.filter(item => item.type.toLowerCase() === queryParams.type.toLowerCase());
          this.filterArray[0].value = setTypeParamsFilter[0].id;
        } else {
          this.isPageNotFoundComp = true;
          return;
        }
      }
      if (queryParams.zipcode) {
        this.zipcodeService.getZipcodeId(this.zipcode).subscribe(
          async resdata => {
            const res = resdata.body;
            if (res.locationCodeId) {
              if (queryParams.locationcode && queryParams.locationcode.includes('150')) {
                try {
                  const getLocDataApiCall = await this.getLocDataApi();
                  let getLocData = getLocDataApiCall.body;
                  if (getLocData) {
                    getLocData = getLocData.LocationDetails.filter(item => item.Code.toLowerCase() === queryParams.locationcode.toLowerCase());
                    this.locationCode = getLocData[0].Id;
                    this.sessionStorageService.setItem('location_code', this.locationCode);
                  }
                } catch (err) {
                  this.isPageNotFoundComp = true;
                  return;
                }
              }
              this.locationService.getLocationsDataByLocationCode(queryParams.locationcode).subscribe(async data => {
                this.locationCodeData = data.body[0];
                this.locationCode = this.locationCodeData.locationCodeId;
                this.sessionStorageService.setItem('location_code', this.locationCode);
                if (res.serviceState === this.locationCodeData.serviceState) {
                  if (queryParams.zipcode && !queryParams.locationcode) {
                    this.locationGo.go(`/products/${this.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`);
                  }
                  if (queryParams.locationcode && this.locationCodeData.locationCode.toLowerCase() !== queryParams.locationcode.toLowerCase()) {
                    this.isPageNotFoundComp = true;
                    return;
                  }
                  this.sessionStorageService.setItem('websitePartnerName', data.body[0].websitePartnerName);
                  this.partnerName = this.sessionStorageService.getItem('websitePartnerName');
                  /* HWR-1445 Don't show provider prompt if entered zipcode matches with zipcode stored from provider check and providerSelected is true */
                  let checkMultiZipcode: any;
                  if (this.userDetails) {
                    await this.getAllCustomerAddressesApi();
                    if (this.globalVariables.getAllCustomerAddresses) {
                      checkMultiZipcode = this.globalVariables.getAllCustomerAddresses.filter(
                        item => item.serviceAddress.zipcode === queryParams.zipcode,
                      );
                    }
                  }
                  const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
                    ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
                    : '';
                  if (
                    data.body[0].websitePartnerName !== '' &&
                    (!(
                      providerSelectionDetails &&
                      providerSelectionDetails.zipCode === queryParams.zipcode &&
                      providerSelectionDetails.providerSelected &&
                      providerSelectionDetails.locationCodeId === this.sessionStorageService.getItem('location_code')
                    ) ||
                      (checkMultiZipcode && checkMultiZipcode.length > 1))
                  ) {
                    this.openModalPopup();
                  } else {
                    this.productService.setCategories();
                    this.applyCategory();
                    if (providerSelectionDetails && providerSelectionDetails.zipCode !== queryParams.zipcode) {
                      this.sessionStorageService.setItem('providerSelectionDetails', '{}');
                    }
                  }
                } else {
                  this.isPageNotFoundComp = true;
                }
              });
            }
          },
          error => {
            if (error.status === 404) {
              this.isPageNotFoundComp = true;
            }
          },
        );
      }
    });
  }

  ngAfterViewInit() {
    this.setPageMetaData(false);
    this.seoService.generateTitleTags(this.seoConfig);
    let urlOperation: any = window.location.pathname.split('/');
    const micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
    if (urlOperation.length > 0 && ['product'].includes(urlOperation[0])) {
      this.seoService.metaSetNoIndexNoFollow();
    } else {
      this.seoService.setCanonicalTags();
    }
  }

  public getLocDataApi(): Promise<any> {
    return this.productService.getLocationDataCRM(this.route.snapshot.params.locationcode).toPromise();
  }

  setType(option: String) {
    this.filterArray[0].value = option;
    this.filterProduct();
  }

  setCategory(option: String) {
    this.filterArray[1].value = option || '';
    this.filterProduct();
  }

  filterProduct() {
    const arrayOfPrograms = [];
    if (this.filterArray[2].value && this.filterArray[2].value !== '') {
      forEach(this.filterArray[2].value.programs, data => {
        arrayOfPrograms.push(data.programId);
      });
    }
    const catogoryArray = arrayOfPrograms ? join(arrayOfPrograms, ',') : '';
    const categoryIds = !catogoryArray ? '' : `&program_ids=${catogoryArray}`;
    this.queryString = '';
    const optionLength = this.filterArray.length;
    for (let i = 0; i < optionLength; i++) {
      if (this.filterArray[i].value && this.filterArray[i].key !== 'category_ids' && this.filterArray[i].key !== 'program_ids') {
        if (!this.queryString) {
          this.queryString = `${this.filterArray[i].key}=${this.filterArray[i].value}`;
        } else {
          this.queryString = `${this.queryString}&${this.filterArray[i].key}=${this.filterArray[i].value}`;
        }
      }
    }
    this.queryString += categoryIds;
    this.getProducts(this.queryString);
  }

  async getProducts(query?: String) {
    this.showLoader = true;
    if (this.partnerName) {
      await this.productService.getProviderContent(this.locationCode).subscribe(
        locationData => {
          this.locData = locationData.body;
        },
        () => {
          // error handling
          this.locData = [];
        },
      );
    } else {
      this.locData = [];
    }
    this.productService.getMarketingCodes(query, this.locationCode).subscribe(
      data => {
        if (data.status === 200) {
          const resData = data.body;
          this.total = resData.total;
          /** JIRA HWR-2517 * */
          if (this.user) {
            this.removeActiveSuspendedProducts(resData.result);
          } else {
            this.setProductIndex(resData.result);
            this.showLoader = false;
          }
        }
      },
      () => {
        this.total = 0;
        this.products = [];
        this.showLoader = false;
      },
    );
  }

  dropdownFilteredOpearation(type, value) {
    /* storing data from params */
    /* const savedAddress = JSON.parse(this.sessionStorageService.getItem('selectedAddress'));
    if (savedAddress) {
      zip = savedAddress.zipcode;
      locationCode = this.sessionStorageService.getItem('location_code');
    } */
    const zip = this.zipcode;
    this.filterArray[2].value = value;
    if (type === 'type') {
      this.setType(value);
      this.filterArray[1].value = '';
      this.categoryNameVal = '';
    } else if (type === 'service') {
      this.categoryNameVal = value.categoryName;
      this.setCategory(value._id);
    }
    if (zip) {
      let categoryParams: any;
      const typeParams = environment.types.filter(item => item.id === this.filterArray[0].value);
      if (this.categoryNameVal) {
        categoryParams = this.categoryNameVal.toString().split(' ').join('-').split(',').join('');
      }
      let queryString = `/products/${zip}/${this.locationCodeData.locationCode.toLowerCase()}`;
      queryString += typeParams ? `/${typeParams[0].type.toLowerCase()}` : '';
      queryString += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
      //   this.locationGo.go(`/products/${zip}/${(this.locationCodeData.locationCode).toLowerCase()}/${(typeParams[0]['type']).toLowerCase()}/${(categoryParams[0]['type']).toLowerCase()}`);
      this.locationGo.go(queryString);
    } else {
      this.locationGo.go('/products');
    }
    this.setPageMetaData(true, value.categoryName);
    this.seoService.generateTitleTags(this.seoConfig);
    if (this.zipcode) {
      this.seoService.metaSetNoIndexNoFollow();
    } else {
      this.seoService.setCanonicalTags();
    }
  }

  openModalPopup() {
    if (this.isbrowser) {
      this.simpleModalService
        .addModal(
          ProviderModalComponent,
          {
            zipcode: this.zipcode,
          },
          {
            wrapperClass: 'modal-main-class in',
            closeOnClickOutside: false,
          },
        )
        .subscribe(isConfirmed => {
          if (isConfirmed) {
            this.showProvider = true;
            this.productService.setCategories();
            this.applyCategory();
          } else if (this.backPress) {
            this.backPress = false;
          } else {
            this.partnerName = '';
            this.sessionStorageService.setItem('websitePartnerName', '');
            this.notMyProvider();
          }
        });
    }
    if (!this.isbrowser) {
      this.showProvider = true;
      this.productService.setCategories();
      this.applyCategory();
    }
  }

  notMyProvider() {
    const savedAddress = this.sessionStorageService.getItem('selectedAddress');
    this.selectedCity = '';
    if (savedAddress) {
      const address = JSON.parse(savedAddress);
      if (address.rawData && address.rawData.address_components.length) {
        this.selectedCity = address.rawData.address_components[1].short_name;
      }
    }

    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      resData => {
        const data = resData.body;
        if (data.length > 0) {
          this.sessionStorageService.setItem('location_code', data[0].locationCodeId);
          this.productService.setCategories();
          const locationData = {
            zipcode: this.zipcode,
            location_code: data[0].locationCodeId,
          };
          this.locationCode = data[0].locationCodeId;
          const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
            ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
            : '';
          providerSelectionDetails.locationCodeId = data[0].locationCodeId;
          providerSelectionDetails.providerValue = 'no';
          this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerSelectionDetails));
          this.getMicrositeContent(locationData, data[0].locationCode);
        } else {
          this.getLocationDataState();
        }
      },
      () => {
        this.total = 0;
        this.products = [];
        this.showLoader = false;
      },
    );
  }

  getLocationDataState() {
    this.selectedCity = '';
    this.productService.getLocationData(this.selectedCity, this.locationCodeData.serviceState).subscribe(
      respDataReceived => {
        const dataReceived = respDataReceived.body;
        if (dataReceived.length > 0) {
          this.sessionStorageService.setItem('location_code', dataReceived[0].locationCodeId);
          this.productService.setCategories();
          const locationData = {
            zipcode: this.zipcode,
            location_code: dataReceived[0].locationCodeId,
          };
          this.locationCode = dataReceived[0].locationCodeId;
          const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
            ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
            : '';
          providerSelectionDetails.locationCodeId = dataReceived[0].locationCodeId;
          providerSelectionDetails.providerValue = 'no';
          this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerSelectionDetails));
          this.getMicrositeContent(locationData, dataReceived[0].locationCode);
        } else {
          this.getLocationCodeCrm(this.locationCodeData.locationCode);
        }
      },
      () => {
        this.total = 0;
        this.products = [];
        this.showLoader = false;
      },
    );
  }

  getLocationCodeCrm(locationCode: any) {
    this.productService.getLocationDataCRM(locationCode).subscribe(
      respLocation => {
        const location = respLocation.body;
        this.sessionStorageService.setItem('location_code', location.LocationDetails[0].Id);
        this.locationGo.go(`/products/${this.zipcode}/${location.LocationDetails[0].Code.toLowerCase()}/residential`);
        const locationData = {
          zipcode: this.zipcode,
          location_code: location.LocationDetails[0].Id,
        };
        this.locationCode = location.LocationDetails[0].Id;
        const providerSelectionDetails = this.sessionStorageService.getItem('providerSelectionDetails')
          ? JSON.parse(this.sessionStorageService.getItem('providerSelectionDetails'))
          : '';
        providerSelectionDetails.locationCodeId = location.LocationDetails[0].Id;
        providerSelectionDetails.providerValue = 'no';
        this.sessionStorageService.setItem('providerSelectionDetails', JSON.stringify(providerSelectionDetails));
        this.getMicrositeContent(locationData, location.LocationDetails[0].Code);
        this.productService.setCategories();
      },
      () => {
        this.total = 0;
        this.products = [];
        this.showLoader = false;
      },
    );
  }

  addressHandler(event) {
    if (event) {
      if (this.zipcode !== event.zipcode) {
        this.fetchProducts = false;
      }
      this.zipcode = event.zipcode;
      this.locationCode = event.location_code;
      this.type = 'residential';
      const savedAddress = this.sessionStorageService.getItem('selectedAddress');
      if (savedAddress) {
        this.selectedAddress = JSON.parse(savedAddress);
      }
      this.productService.setCategories();
      this.productService.getMarketingCodes('', event.location_code).subscribe(
        data => {
          if (data.status === 200) {
            this.locationService.getLocationsContent(event.location_code || this.locationCode).subscribe(
              dataReceived => {
                this.locationCodeData = dataReceived.body;
                this.getMicrositeContent(event);
              },
              () => {},
            );
          } else if (data.status !== 200) {
            this.showErrorModal('Error', 'No service available in your vicinity.');
          }
        },
        () => {
          this.simpleModalService.addModal(
            ErrorModalComponent,
            {
              title: 'We are sorry. We do not currently offer services in your location.',
              message: 'Please check back soon for product and service availability in your area.',
              showButton: 'close',
              alertType: 'info-type',
            },
            {
              wrapperClass: 'modal-main-class in',
              closeOnClickOutside: true,
            },
          );
        },
      );
    } else {
      this.products = [];
      this.total = 0;
    }
  }

  getMicrositeContent(event: any, locationCode?: any) {
    const locationCodeParams = locationCode || this.locationCodeData.locationCode;
    this.locationService.getMicroSiteForLocation(locationCode || this.locationCodeData.locationCode).subscribe(
      respDataMicroSite => {
        const dataMicroSite = respDataMicroSite.body;
        let categoryParams: any;
        /* HWR-3122 set Product Listing Page Filters per property type - Start */
        const typeParams = this.type ? this.type.toLowerCase() : 'residential';
        /* HWR-3122 set Product Listing Page Filters per property type - End */
        if (this.categoryNameVal) {
          categoryParams = this.categoryNameVal.toString().split(' ').join('-').split(',').join('');
        }
        if (dataMicroSite.level === 'default') {
          if (this.sessionStorageService.getItem('providerState')) {
            this.sessionStorageService.setItem('isMicroSite', '0');
            this.sessionStorageService.deleteItem('baseUrlState');
            this.sessionStorageService.deleteItem('providerState');
            if (dataMicroSite.site.siteName) {
              dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
            } else {
              dataMicroSite.site.siteName = '';
            }
            let queryString = dataMicroSite.site.siteName
              ? `/${dataMicroSite.site.siteName}/products/${event.zipcode}/${locationCodeParams.toLowerCase()}`
              : `/products/${event.zipcode}/${locationCodeParams.toLowerCase()}`;
            queryString += typeParams ? `/${typeParams}` : '';
            queryString += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
            if (this.isbrowser) {
              window.location.href = queryString;
            }
          } else {
            //   this.router.navigate(['/products/', event.zipcode, (this.locationCodeData.locationCode).toLowerCase()]);
            let redirctUrl = `/products/${event.zipcode}/${locationCodeParams.toLowerCase()}`;
            redirctUrl += typeParams ? `/${typeParams}` : '';
            redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
            this.router.navigate([redirctUrl]);
          }
        } else {
          this.sessionStorageService.setItem('isMicroSite', '1');

          if (dataMicroSite.site.siteName) {
            dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
          } else {
            dataMicroSite.site.siteName = '';
          }

          let providerState = '';
          providerState = this.sessionStorageService.getItem('providerState')
            ? this.sessionStorageService.getItem('providerState').toLowerCase()
            : '';
          if (providerState === dataMicroSite.site.siteName) {
            //   this.router.navigate(['/products/', event.zipcode, (this.locationCodeData.locationCode).toLowerCase()]);
            let redirctUrl = `/products/${event.zipcode}/${locationCodeParams.toLowerCase()}`;
            redirctUrl += typeParams ? `/${typeParams}` : '';
            redirctUrl += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
            this.router.navigate([redirctUrl]);
          } else {
            if (dataMicroSite.site.siteName) {
              dataMicroSite.site.siteName = dataMicroSite.site.siteName.toLowerCase();
            } else {
              dataMicroSite.site.siteName = '';
            }
            this.sessionStorageService.setItem('providerState', dataMicroSite.site.siteName);
            this.sessionStorageService.setItem('baseUrlState', dataMicroSite.site.siteName);
            let queryString = dataMicroSite.site.siteName
              ? `/${dataMicroSite.site.siteName}/products/${event.zipcode}/${locationCodeParams.toLowerCase()}`
              : `/products/${event.zipcode}/${locationCodeParams.toLowerCase()}`;
            queryString += typeParams ? `/${typeParams}` : '';
            queryString += categoryParams ? `/${categoryParams.toLowerCase()}` : '';
            if (this.isbrowser) {
              window.location.href = queryString;
            }
          }
        }
      },
      () => {},
    );
  }

  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => {});
  }

  changeAddress() {
    this.isZipcodeChangeClicked = true;
    this.selectedAddress = {};
  }

  mapCategories(categories: any) {
    categories.forEach(category => {
      this.mapCategoriesById[category._id] = category;
    });
  }

  applyCategory() {
    const categoryId = this.filterArray[1].value;
    const catArray = this.categories;
    if (categoryId) {
      this.filterArray[2].value = find(catArray, o => o._id === categoryId);
    }
    if (!this.fetchProducts) {
      this.filterProduct();
      this.fetchProducts = true;
    } else {
      this.showLoader = true;
      this.products = this.paginatedProducts[0];
      this.showLoader = false;
    }
    if (this.isbrowser) {
      window.scrollTo(250, 200);
    }
  }

  setPageMetaData(dropdown?: boolean, titles?: string) {
    if (!dropdown) {
      if (this.route.snapshot.queryParams.name !== undefined) {
        titles = `${this.route.snapshot.queryParams.name.split('-').join(' ')} |`;
      } else {
        titles = '';
      }
    } else if (titles !== undefined) {
      titles = `${titles} |`;
    } else {
      titles = '';
    }
    this.seoConfig = {
      title: `${titles} Service Line Warranties | American Water Resources`,
      description: `American Water Resources offers affordable home protectionsadasdsad programs
      that help protect against unexpected repair costs for service line emergencies.`,
      keywords: `service line warranties, water line protection, sewer line protection,
      in home plumbing emergencies, electric line protection, power surge protection, heating system repair, cooling system repair`,
      image: this.logoURL,
      slug: '',
      sitename: '',
      type: '',
      summary: '',
      url: '',
    };
  }

  getImage(imageName) {
    return this.utilsService.buildImageUrl(imageName);
  }

  loaderValue(value) {
    this.showLoader = value;
  }

  public getAllCustomerAddressesApi(): Promise<any> {
    return this.userService.getAllCustomerAddresses(this.userDetails.user._id).toPromise();
  }

  // HWR-2517 : remove active and suspended enrolled products for single service address
  removeActiveSuspendedProducts(resData) {
    let customerNo = '';
    let serviceaddress;
    const serviceaddresses: any = JSON.parse(this.sessionStorageService.getItem('serviceaddress'));
    if (!['null', 'undefined', undefined, null, ''].includes(serviceaddresses)) {
      serviceaddress = serviceaddresses.filter(item => item.serviceAddress.zipcode === this.zipcode);
    }
    if (['null', 'undefined', undefined, null, ''].includes(serviceaddresses) || this.globalVariables.routeReferer) {
      const userProfileData = this.sessionStorageService.getItem('userProfileDetails')
        ? JSON.parse(this.sessionStorageService.getItem('userProfileDetails'))
        : this.globalVariables.userProfileDetails;
      if (userProfileData) {
        serviceaddress = userProfileData.address.filter(item => item.serviceAddress.zipcode === this.zipcode);
      }
    }
    if (serviceaddress && serviceaddress.length === 1) {
      customerNo = serviceaddress[0].customerNo;
      if (this.user && customerNo) {
        let url = `?crmCustomerNumber=${customerNo}`;
        url += '&isActive=true';
        this.orderService.getOrderByAddress(this.user.user._id, url).subscribe(data => {
          if (data.status === 200) {
            const enrolledData = data.body;
            const suspendednEnrolledProductList = enrolledData
              .filter(item => item.crmStatus === '6')
              .map(value => value.productDetails.program.products);
            const uniqSuspendednEnrolledProductList = uniq(flatten(suspendednEnrolledProductList));
            uniqSuspendednEnrolledProductList.forEach(ele => {
              resData = resData.filter(item => !item.program.products.includes(ele));
            });
            const marketingCodes = enrolledData.filter(item => item.crmStatus === '2').map(value => value.productDetails.marketingCode);
            marketingCodes.forEach(code => {
              resData = resData.filter(item => item.marketingCode !== code);
            });
            const activeEnrolledProductList = enrolledData.filter(item => item.crmStatus === '2').map(value => value.productDetails.program.products);
            const uniqActiveEnrolledProductList = uniq(flatten(activeEnrolledProductList));
            resData = resData.filter(
              item => item.program.products.length >= 1 && !item.program.products.every(product => uniqActiveEnrolledProductList.includes(product)),
            );
            this.total = resData.length;
            this.setProductIndex(resData);
            this.showLoader = false;
          }
        });
      } else {
        this.setProductIndex(resData);
        this.showLoader = false;
      }
    } else {
      this.setProductIndex(resData);
      this.showLoader = false;
    }
  }

  setProductIndex(resData) {
    this.products = resData;
    this.showOldPriceContainer(resData);
    if (this.products.length > 0) {
      for (let i = 0; i < this.products.length; i++) {
        if(this.products[i].isAsteriskAdded) this.isAsteriskPresent = true;
        this.shareIconIndexArray.push({
          value: false,
        });
      }
    }
  }

  // HWR-2982 Reposition How It Works Banner.
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.setFixedHeader();
  }

  setFixedHeader() {
    if (navigator.userAgent.match(/Trident\/7\./)) {
      if (window.pageYOffset > 100) {
        this.fixedHeader = true;
      } else {
        this.fixedHeader = false;
      }
    } else if (window.scrollY > 100) {
      this.fixedHeader = true;
    } else {
      this.fixedHeader = false;
    }
  }

  // HWR-3121 Remove pagination from product listing page
  scrollToTop() {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      'slow',
    );
  }

  showOldPriceContainer(products) {
    if (products) {
      products.forEach(item => {
        if (item.oldPrice || (item.oldPriceText && !['', undefined, 'undefined', null, 'null'].includes(item.oldPriceText))) {
          this.setOldPriceFlag = true;
        }
      });
    }
  }
}
