<ng-container *ngIf="isbrowser">
  <article class="card text-center cardsAlign" [ngClass]="{ 'card-height': showOldPrice }" *ngIf="product" ngsReveal>
    <ng-container *ngIf="!isFlipped">
      <div class="d-flex program-name ga-track-learn-more" (click)="learnMoreHandler(product)">
        {{ product?.webProgramName }}
      </div>
      <div *ngIf="product.productTag === 'Most Popular'" class="w-100 img-promotion">
        <img src="img/productList/mostPopular.png" class="img-responsive float-right" alt="Most Popular" />
      </div>
      <div *ngIf="product.productTag === 'Best Value'" class="w-100 img-promotion">
        <img src="img/productList/bestValue.png" class="img-responsive float-right" alt="Best Value" />
      </div>
      <img
        [src]="getProductCardImage(product)"
        [alt]="product?.webProgramName"
        class="card-img ga-track-learn-more"
        (click)="learnMoreHandler(product)"
      />
      <div class="w-100 img-flip">
        <img src="img/productList/info.png" class="img-responsive float-right mr-3" alt="Info" (click)="flipHandler(true)" />
      </div>
    </ng-container>

    <ng-container *ngIf="isFlipped">
      <div *ngIf="product.productTag === 'Most Popular'" class="w-100 img-promotion">
        <img src="img/productList/mostPopular.png" class="img-responsive float-right" alt="Most Popular" />
      </div>
      <div *ngIf="product.productTag === 'Best Value'" class="w-100 img-promotion">
        <img src="img/productList/bestValue.png" class="img-responsive float-right" alt="Best Value" />
      </div>
      <div class="program-covered-repair w-100">
        <div class="text-left">
          <label class="covered-label">What's Cover</label>
        </div>
        <div class="col-12 max-width-tab-100 max-height pl-0" [ngClass]="{ 'scroll-li list-max-height': product?.coveredRepairs?.length > 4 }">
          <ol class="covered-repairs-list initial">
            <li *ngFor="let coveredRepair of product?.coveredRepairs">{{ coveredRepair?.value }}</li>
          </ol>
        </div>
      </div>
      <div class="w-100 img-flip">
        <img src="img/productList/cancel.png" class="img-responsive float-right mr-3" alt="Cancel" (click)="flipHandler(false)" />
      </div>
    </ng-container>

    <div class="card-body w-100">
      <!-- first row start -->
      <div class="row m-0">
        <div class="col-12 p-0 d-flex align-items-center justify-content-between">
          <h3 *ngIf="specialProduct.includes(product?.programId); then leak; else notLeak"></h3>
          <ng-template #leak>
            <button (click)="goToPaymentOptions()" class="btn btn-link float-left non-href-links btn-sm primary-link change-link leak-program">
              Click here for payment options
            </button>
          </ng-template>
          <ng-template #notLeak>
            <div class="price-cls d-flex align-items-center">
              <span class="notranslate">${{ product?.monthlyPrice | slice : 0 : -2 }}</span>
              <span class="small-txt ml-1">Per Month {{ product?.isAsteriskAdded ? ' *' : '' }}</span>
            </div>
            <div class="cost-saving-container" *ngIf="product?.costSaving && product?.costSaving > 0">
              <span class="cost-saving">SAVE ${{ product?.costSaving | slice : 0 : -2 }}</span>
            </div>
          </ng-template>
        </div>
        <div class="col-12 p-0">
          <div class="old-price-container" *ngIf="showOldPrice">
            <div class="d-flex flex-column">
              <span class="old-price" *ngIf="product && product.oldPrice && product.oldPrice !== ''"
                >${{ product.oldPrice | slice : 0 : -2 }} Per Month</span
              >
              <div class="rounded old-price-text p-1" *ngIf="product && product.oldPriceText && product.oldPriceText !== ''">
                <span class="text-danger">{{ product.oldPriceText }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- first row end -->
      <div class="row m-0 mt-15">
        <button
          *ngIf="!specialProduct.includes(product?.programId) && !refCheck"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="ENROLL NOW"
        >
          Enroll Now<img src="img/productList/arrow.png" class="img-responsive ml-2" alt="Arrow" />
        </button>
        <button
          *ngIf="specialProduct.includes(product?.programId)"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="BUY NOW"
        >
          Buy Now<img src="img/productList/arrow.png" class="img-responsive ml-2" alt="Arrow" />
        </button>
        <button
          *ngIf="refCheck"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-enroll-now"
          [ngClass]="{ 'commercial-enroll-now': product?.contactType === 3 }"
          title="ADD UPGRADE TO CART"
        >
          Add Upgrade To Cart<img src="img/productList/arrow.png" class="img-responsive ml-2" alt="Arrow" />
        </button>
      </div>
      <!-- last row start -->
      <div class="row m-0 d-none">
        <div class="col-4 bottom-btn ga-track-learn-more" (click)="learnMoreHandler(product)" title="Learn More">
          {{ butttonText }}
        </div>

        <ul class="social social-list social-show" *ngIf="shareIcon && shareIconIndexArray[index]['value']">
          <li class="social-icons social-twitter" (click)="learnMoreNativeLink(product, 'twitter')">
            <a>
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-facebook" (click)="learnMoreNativeLink(product, 'fb')">
            <a>
              <i class="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-linkedin" (click)="learnMoreNativeLink(product, 'linkedin')">
            <a>
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-pinterest" (click)="learnMoreNativeLink(product, 'pinterest')">
            <a>
              <i class="fa fa-pinterest" aria-hidden="true"></i>
            </a>
          </li>
          <li class="social-icons social-mail" (click)="learnMoreNativeLink(product, 'mail')">
            <a>
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <div class="col-8 button-row">
          <div class="col-6 bottom-btn btn-share" (click)="showSocialIcons()" title="Share">
            <span>Share<img src="img/my-account/share.svg" class="img-responsive img-share" alt="Share" /></span>
          </div>
          <div class="col-6 bottom-btn" (click)="openCreateForm(product)" title="Review">
            <span>Review<img src="img/my-account/review.svg" class="img-responsive img-review" alt="Review" /></span>
          </div>
        </div>
      </div>
      <div class="commercial-row" *ngIf="product.contactType === 3">
        <div class="commercial-product">COMMERCIAL</div>
      </div>
      <!-- last row end -->
    </div>
  </article>
</ng-container>
