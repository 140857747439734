<main class="main" [ngClass]="{ 'hide-background': isRegisterSucess }">
  <div class="wrapper" [ngClass]="{ 'success-reg-wrapper': isRegisterSucess }">
    <div class="card" [ngClass]="{ 'success-reg-card': isRegisterSucess }">
      <div *ngIf="!loadForgotPassword">
        <div class="title">
          <h1 class="title title-large" [ngClass]="{ 'success-reg-sign-in-title': isRegisterSucess }">Sign-In</h1>
          <p *ngIf="!isRegisterSucess" class="title title-subs">
            Don't have an account?
            <span class="ml-2">
              <button class="startHere linktext linktext-btn font-weight-500 notranslate" (click)="goToRegister()">
                <span class="startText">START HERE</span>
              </button>
            </span>
          </p>
        </div>
        <form class="form" [ngClass]="{ 'success-reg-form': isRegisterSucess }" role="form" #signInForm="ngForm" novalidate>
          <div class="form-group" [ngClass]="{ 'success-reg-form-group': isRegisterSucess }">
            <input
              type="email"
              class="form-control round-form"
              [hosAutoFocus]="true"
              (keydown)="keyPresshandler()"
              name="usernameDesk"
              [pattern]="email_regex"
              placeholder="Email Address"
              required
              ngModel
              #usernameDesk="ngModel"
            />
            <small id="emailHelp" class="form-text text-danger ml-2" *ngIf="isClicked && !usernameDesk?.valid">Email Id is required.</small>
            <small id="emailHelp" class="form-text text-danger ml-2" *ngIf="isClicked && !usernameDesk?.valid && usernameDesk?.dirty"
              >Please enter valid email address.</small>
          </div>
          <div class="form-group" [ngClass]="{ 'success-reg-form-group': isRegisterSucess }">
            <input
              type="password"
              class="form-control round-form"
              (keydown)="keyPresshandler()"
              name="passwordDesk"
              placeholder="Password"
              required
              ngModel
              #passwordDesk="ngModel"
            />
            <small id="emailHelp" class="form-text text-danger ml-2" *ngIf="isClicked && !passwordDesk?.valid">Please enter password.</small>
            <label class="form-text text-danger" *ngIf="loginError">{{ loginError }}</label>
            <!-- <a *ngIf="loginError" href="{{ redirect_link }}" class="linktext forgot-pwd notranslate"
              >Please click here to set the password in azure</a
            > -->
          </div>
          <div class="form-group" *ngIf="!isRegisterSucess && !loginError">
            <div class="checkbox">
              <label>
                <input type="checkbox" class="checkbox-field" name="remember" [(ngModel)]="isRememberMe" #remember="ngModel" />
                <i class="helper"></i>&nbsp;&nbsp;
                <span class="rememberMeText">Remember me </span>
              </label>
            </div>
          </div>
          <div class="form-group form-group--4" [ngClass]="{ 'success-reg-form-group': isRegisterSucess }">
            <a (click)="forgotPassword()" class="linktext forgot-pwd notranslate">Forgot Password?</a>
            <button
              type="submit"
              *ngIf="loggingIn"
              (click)="onSubmit(signInForm, 1)"
              class="input-submit notranslate"
              id="ga-track-login"
              [ngClass]="{ 'success-reg-input-submit': isRegisterSucess }"
              title="LOG IN"
            >
              LOG IN
            </button>
            <button type="submit" *ngIf="!loggingIn" class="input-submit">
              <i class="fa fa-spinner fa-spin"></i>
            </button>
          </div>
        </form>
      </div>

      <div *ngIf="loadForgotPassword">
        <hos-new-forgot-password (hideForgotPassword)="hideForgotPassword()" [isRegisterSucess]="isRegisterSucess"></hos-new-forgot-password>
      </div>
    </div>
  </div>
</main>
