<ng-container *ngIf="isbrowser">
  <article class="card text-center cardsAlign" [ngClass]="{ 'card-height': showOldPrice }" *ngIf="product" ngsReveal>
    <div class="top-section">
      <img
        [src]="getProductCardImage(product)"
        [alt]="product?.webProgramName"
        class="card-img ga-track-learn-more"
        (click)="learnMoreHandler(product)"
      />
      <div class="program-name ga-track-learn-more" (click)="learnMoreHandler(product)">
        {{ product?.webProgramName }}
      </div>

      <div class="related-item-desc">
        <div class="description" title="{{ product?.coverSubheading }}">
          {{ product?.coverSubheading }}
        </div>
        <div class="spz-learn-more" (click)="learnMoreHandler(product)">Learn More</div>
      </div>
    </div>

    <div class="bottom-section">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="price-cls float-left">
            <span class="notranslate">${{ product?.monthlyPrice | slice : 0 : -2 }}</span>
            <span class="small-txt"
              >Per Month {{ product?.isAsteriskAdded ? ' *' : ''
              }}<span *ngIf="product?.costSaving && product?.costSaving > 0">(Save ${{ product?.costSaving | slice : 0 : -2 }})</span></span
            >
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="old-price-container" *ngIf="showOldPrice && product && product?.oldPrice">
            <div class="d-flex flex-column">
              <span class="old-price" *ngIf="product && product?.oldPrice && product?.oldPrice !== ''"
                >${{ product.oldPrice | slice : 0 : -2 }} Per Month</span
              >
              <div class="rounded old-price-text p-1" *ngIf="product && product?.oldPriceText && product?.oldPriceText !== ''">
                <span class="text-danger">{{ product.oldPriceText }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 mt-15">
        <button
          *ngIf="!specialProduct.includes(product?.programId) && !refCheck"
          (click)="addProductToCart(product)"
          class="btn w-100 enroll-now skiptranslate ga-track-enroll-now"
          title="Add to Cart"
        >
          Add to Cart
        </button>
        <button *ngIf="refCheck" (click)="addProductToCart(product)" class="btn w-100 enroll-now skiptranslate ga-track-enroll-now" title="UPGRADE">
          Upgrade
        </button>
      </div>
    </div>
  </article>
</ng-container>
