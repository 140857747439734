import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NguCarouselConfig } from '@ngu/carousel';
import { Location } from '@angular/common';
import { SimpleModalService } from 'ngx-simple-modal';
import { SessionStorageService } from '../../../core/services/session-storage.service';
import { ErrorModalComponent } from '../../../core/component/error-modal/error-modal.component';
import { SiteContentService } from '../../../core/services/siteContents';
import { ProductService } from '../../../product/services/product.service';
import { LocationService } from '../../services/location.service';
import { environment } from '../../../../environments/environment';
import { DotCMSService } from '../../../core/services/dotcms.service';

import { UtilsService } from '../../../core/services/utils.service';
import { DotCMSCategory } from '../../../core/model/dotcms-constants.model';
import { GlobalVariables } from '../../../core/services/global-variables.service';

@Component({
  selector: 'hos-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.scss'],
})
export class HeroBannerComponent implements OnInit {
  @Input() zipcodeSend: any;

  siteContent: any;

  dotCMSBannerData: any;

  public carouselBanner: NguCarouselConfig;

  locationCodeData: any;

  partnerLogo: String;

  customer_service_number: String;

  screenWidth: number;

  zipcodeLookupText: any;

  bannerImageUrl: String = "";

  bannerTabletImageURL: String = "";

  bannerMobileImageURL: String = "";

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  constructor(
    private router: Router,
    private simpleModalService: SimpleModalService,
    private siteContentService: SiteContentService,
    private sessionStorageService: SessionStorageService,
    private dotCMSService: DotCMSService,
    private productService: ProductService,
    private locationService: LocationService,
    private location: Location,
    private utilsService: UtilsService,
    private globalVariables: GlobalVariables,
  ) {
    const url = sessionStorageService.getItem('providerState');
    if (url) {
      this.getDotCMSContent(DotCMSCategory.HOS_BANNER.concat('', url.toLowerCase()));
      this.utilsService.event.subscribe(eventData => {
        if (Object.keys(eventData).length !== 0) {
          this.utilsService.getPartnerDetails$().subscribe(
            data => {
              const resp = data.body;
              this.zipcodeLookupText = resp.site.zipcodeLookupText;
              // Get banner image from site DB for baltimore and phoenix microsite
              this.bannerImageUrl = resp?.site?.homepage?.bannerImageUrl;
              this.bannerMobileImageURL = resp?.site?.homepage?.bannerMobileImageURL;
              this.bannerTabletImageURL = resp?.site?.homepage?.bannerTabletImageURL;
              /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
              const locationCodeId = Array.isArray(resp.locationCodeId) ? resp.locationCodeId[0] : '';
              if (![undefined, 'undefined', null, 'null', ''].includes(locationCodeId)) {
                this.sessionStorageService.setItem('location_code', locationCodeId);
              }
              /** ********** HWR-3642: multiple locations for FEH microsite  ******** */
              this.partnerLogo =
                !!resp.locationLogo && !!resp.locationLogo.url
                  ? `${environment.Image_API_Endpoint}
${environment.Content_service_API_Endpoint}${resp.locationLogo.url}`
                  : '';
            },
            () => {
              this.getDotCMSContent(DotCMSCategory.HOS_BANNER);
            },
          );
        }
      });
    } else {
      this.utilsService.event.subscribe(eventData => {
        if (Object.keys(eventData).length !== 0) {
          this.utilsService.getPartnerDetails$().subscribe(data => {
            const resp = data.body;
            this.zipcodeLookupText = resp.site.zipcodeLookupText;
          });
        }
      });
      this.getDotCMSContent(DotCMSCategory.HOS_BANNER);
    }
  }

  ngOnInit() {
    this.customer_service_number = environment.customer_service_number;
    this.carouselBanner = {
      grid: {
        xs: 1,
        sm: 1,
        md: 1,
        lg: 1,
        all: 0,
      },
      slide: 2,
      speed: 1000,
      interval: {
        timing: 7000,
      },
      point: {
        visible: false,
      },
      load: 2,
      custom: 'banner',
      touch: false,
      loop: true,
      easing: 'opacity 2s ease-in-out',
    };
    this.screenWidth = window?.innerWidth ?? 200;
  }

  addressHandler(event) {
    if (event) {
      const locationCode = event.location_code ? event.location_code : '';
      this.locationService.getLocationsContent(event.location_code || this.sessionStorageService.getItem('location_code')).subscribe(
        dataReceived => {
          this.productService.getMarketingCodes('', locationCode).subscribe(
            data => {
              if (data.status === 200) {
                this.locationCodeData = dataReceived.body;
                this.locationService.getMicroSiteForLocation(this.locationCodeData.locationCode).subscribe(
                  dataMicroSite => {
                    if (dataMicroSite.body.level === 'default') {
                      if (this.sessionStorageService.getItem('providerState')) {
                        this.sessionStorageService.setItem('isMicroSite', '0');
                        this.sessionStorageService.deleteItem('baseUrlState');
                        this.sessionStorageService.deleteItem('providerState');
                        window.location.href = `/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                      } else {
                        this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                      }
                    } else {
                      this.sessionStorageService.setItem('isMicroSite', '1');
                      if (dataMicroSite.body.site.siteName) {
                        dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
                      } else {
                        dataMicroSite.body.site.siteName = '';
                      }

                      let providerState = '';
                      providerState = this.sessionStorageService.getItem('providerState')
                        ? this.sessionStorageService.getItem('providerState').toLowerCase()
                        : '';
                      if (providerState === dataMicroSite.body.site.siteName) {
                        this.router.navigate([`/products/${event.zipcode}/${this.locationCodeData.locationCode.toLowerCase()}/residential`]);
                      } else {
                        if (dataMicroSite.body.site.siteName) {
                          dataMicroSite.body.site.siteName = dataMicroSite.body.site.siteName.toLowerCase();
                        } else {
                          dataMicroSite.body.site.siteName = '';
                        }
                        this.sessionStorageService.setItem('providerState', dataMicroSite.body.site.siteName);
                        this.sessionStorageService.setItem('baseUrlState', dataMicroSite.body.site.siteName);
                        window.location.href = `/${dataMicroSite.body.site.siteName}/products/${event.zipcode
                          }/${this.locationCodeData.locationCode.toLowerCase()}/residential`;
                      }
                    }
                  },
                  () => { },
                );
              } else if (data.status !== 200) {
                this.showErrorModal('Error', 'No service available in your vicinity.');
              }
            },
            () => {
              this.simpleModalService.addModal(
                ErrorModalComponent,
                {
                  title: 'We are sorry. We do not currently offer services in your location.',
                  message: 'Please check back soon for product and service availability in your area.',
                  showButton: 'close',
                  alertType: 'info-type',
                },
                {
                  wrapperClass: 'modal-main-class in',
                  closeOnClickOutside: true,
                },
              );
            },
          );
        },
        () => { },
      );
    } else {
      this.sessionStorageService.setItem('selectedAddress', JSON.stringify(''));
    }
  }

  showErrorModal(title: string, message: string) {
    this.simpleModalService
      .addModal(
        ErrorModalComponent,
        {
          title,
          message,
        },
        {
          wrapperClass: 'modal-main-class in',
          closeOnClickOutside: true,
        },
      )
      .subscribe(() => { });
  }

  scrollToSteps() {
    document.getElementById('how-it-works').scrollIntoView({
      behavior: 'smooth',
    });
    document.body.scrollTop -= 60;
  }

  getDotCMSContent(param) {
    this.dotCMSService.getContentByCategory(param).subscribe(
      res => {
        this.dotCMSBannerData = res.data;
        this.dotCMSBannerData.forEach(element => {
          element.subTitle = this.utilsService.filterCompanyName(element.subTitle);
          element.bannerImageURL = this.bannerImageUrl ? this.bannerImageUrl : this.dotCMSService.dotCMSImageById(element.bannerImageURL);
          element.bannerTabletImageURL = this.bannerTabletImageURL ? this.bannerTabletImageURL : this.dotCMSService.dotCMSImageById(element.bannerTabletImageURL);
          element.bannerMobileImageURL = this.bannerMobileImageURL ? this.bannerMobileImageURL : this.dotCMSService.dotCMSImageById(element.bannerMobileImageURL);
        });
        if ((!this.dotCMSBannerData || !this.dotCMSBannerData.length) && param !== DotCMSCategory.HOS_BANNER) {
          this.getDotCMSContent(DotCMSCategory.HOS_BANNER);
        }
      },
      () => { },
    );
  }

  /* HWR-3118 Slider redesign - Start ***** */
  openLoginWindow() {
    let prevUrl;
    const hashValue: any = ![undefined, 'undefined', null, 'null', ''].includes(window.location.hash) ? window.location.hash : '';
    let urlOperation: any = window.location.pathname.split('/');
    const micrositeNames = this.sessionStorageService.getItem('micrositeName')
      ? JSON.parse(this.sessionStorageService.getItem('micrositeName'))
      : environment.micrositeNames;
    // urlOperation = urlOperation.filter(item => !environment.micrositeNames.includes(item) && item !== "");
    urlOperation = urlOperation.filter(item => !micrositeNames.includes(item) && item !== '');
    prevUrl = `${urlOperation.join('/')}${window.location.search}`;
    const urlResult = environment.userRouteIncludes.filter(val => prevUrl.startsWith(val));
    if (urlResult.length > 0) {
      prevUrl = '';
    }

    this.router.navigate(['/user/login'], {
      queryParams: {
        referer: encodeURIComponent(prevUrl + hashValue),
      },
    });
  }

  goToRegister() {
    if (this.router.url.indexOf('/user/register') > -1) {
      window.location.reload();
    } else {
      this.router.navigate(['/user/register'], {
        queryParams: {
          referer: this.router.url,
        },
      });
    }
  }
  /* HWR-3118 Slider redesign - End ***** */
}
