/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-empty */
/**
 * Http Intercepter Service
 * TODO: Add Loader and Toasty Service currently using console log
 * for showing errors and response and request completion;
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError, tap, finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AppSecrets } from '../../app.secrets';
import { GlobalVariables } from './global-variables.service';
import { PlatFormId } from './plateform-id.service';

import { SessionStorageService } from './session-storage.service';

@Injectable()
export class HttpService extends HttpClient {
  public loading = new Subject<{ loading: boolean; hasError: boolean; hasMsg: string }>();

  public secrets: any;

  public platformObj: any;

  constructor(
    handler: HttpHandler,
    public appSecrets?: AppSecrets,
    public platformId?: PlatFormId,
    private sessionStorageService?: SessionStorageService,
  ) {
    super(handler);
    this.secrets = this.appSecrets.getSecrets();
    this.platformObj = this.platformId.getPlatForm();
  }

  /**
   * Performs any type of http request.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  request(request: any | HttpRequest<any>, url?: string, options?: any): Observable<any> {
    try {
      return super.request(request, url, options).pipe(
        catchError((error): Observable<any> => {
          // check response code here if it is 401 call getToken with refresh token as input
          if (error.status === 401) {
            const refreshToken = this.sessionStorageService.getItem('refreshToken');
            if (options.headers.authorization) {
              delete options.headers.authorization;
              options.headers.authorization = refreshToken;
              //  retry request with refersh_token
              return super
                .request(request, url, options)
                .pipe(
                  catchError((err): Observable<any> => {
                    this.sessionStorageService.deleteItem('authHeader');
                    this.sessionStorageService.deleteItem('refreshToken');
                    window.location.href = '/';
                    return throwError(() => err);
                  }),
                )
                .pipe(
                  map((result: HttpResponse<any>) => {
                    if (result) {
                      // store refreshToken and access_token again;
                      this.sessionStorageService.setItem('authHeader', result.headers.get('x-auth-header'));
                      this.sessionStorageService.setItem('refreshToken', result.headers.get('x-refresh-token'));
                      return result;
                    }
                    return throwError(() => new Error('unable to refresh the token'));
                  }),
                );
            }
          } else {
            return throwError(() => error);
          }
        }),
      );
    } catch (e) {}
  }

  /**
   * Performs a request with `get` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  get(url: string, options = {}): Observable<any> {
    this.requestInterceptor();
    return super
      .get(this.getFullUrl(url), {
        headers: this.addAuthorization(),
        ...options,
        observe: 'response',
      })
      .pipe(catchError(this.onCatch.bind(this)))
      .pipe(
        tap(
          (res: HttpResponse<any>) => {
            this.onSubscribeSuccess(res);
          },
          (error: any) => {
            this.onSubscribeError(error);
          },
        ),
      )
      .pipe(
        finalize(() => {
          this.onFinally();
        }),
      );
  }

  getLocal(url: string, options?): Observable<any> {
    return super.get(url, options);
  }

  /**
   * Performs a request with `post` http method.
   * @param url
   * @param body
   * @param options
   * @returns {Observable<>}
   */
  post(url: string, body: any, options = {}): Observable<any> {
    this.requestInterceptor();
    return super
      .post(this.getFullUrl(url), body, {
        headers: this.requestOptions(options),
        observe: 'response',
      })
      .pipe(catchError(this.onCatch.bind(this)))
      .pipe(
        tap(
          (res: HttpResponse<any>) => {
            this.onSubscribeSuccess(res);
          },
          (error: any) => {
            this.onSubscribeError(error);
          },
        ),
      )
      .pipe(
        finalize(() => {
          this.onFinally();
        }),
      );
  }

  /**
   * Performs a request with `put` http method.
   * @param url
   * @param body
   * @param options
   * @returns {Observable<>}
   */
  put(url: string, body: any, options = {}): Observable<any> {
    this.requestInterceptor();
    return super
      .put(this.getFullUrl(url), body, {
        headers: this.requestOptions(options),
        ...options,
        observe: 'response',
      })
      .pipe(catchError(this.onCatch.bind(this)))
      .pipe(
        tap(
          (res: HttpResponse<any>) => {
            this.onSubscribeSuccess(res);
          },
          (error: any) => {
            this.onSubscribeError(error);
          },
        ),
      )
      .pipe(
        finalize(() => {
          this.onFinally();
        }),
      );
  }

  /**
   * Performs a request with `delete` http method.
   * @param url
   * @param options
   * @returns {Observable<>}
   */
  delete(url: string, options = {}): Observable<any> {
    this.requestInterceptor();
    return super
      .delete(this.getFullUrl(url), {
        headers: this.requestOptions(options),
        observe: 'response',
      })
      .pipe(catchError(this.onCatch.bind(this)))
      .pipe(
        tap(
          (res: HttpResponse<any>) => {
            this.onSubscribeSuccess(res);
          },
          (error: any) => {
            this.onSubscribeError(error);
          },
        ),
      )
      .pipe(
        finalize(() => {
          this.onFinally();
        }),
      );
  }

  /**
   * Request options.
   * @param options
   */
  private requestOptions(options: any = {}) {
    try {
      if (Object.keys(options).length === 0) {
        options = {
          'Content-Type': 'application/json',
        };
      }

      /**
       * Handling to get localStroage item only on browser
       */
      if (this.platformObj.isBrowser) {
        const access_token = this.sessionStorageService.getItem('authHeader');
        if (access_token) {
          options.authorization = access_token;
        }
      }
    } catch (e) {}
    return options;
  }

  /**
   * Build API url.
   * @param url
   * @returns {string}
   */
  private getFullUrl(url: string): string {
    // if (this.secrets && this.secrets.APIKey) {
    //   const queryParamExist = this.hasQureyString(url);
    //   const apimParam = `APIKey=${this.secrets.APIKey}`;
    //   if (queryParamExist) {
    //     return `${environment.API_Endpoint + url}&${apimParam}`;
    //   }
    //   return `${environment.API_Endpoint + url}?${apimParam}`;
    // }
    return environment.API_Endpoint + url;
  }

  /**
   * Request interceptor.
   */
  private requestInterceptor(): void {
    // this.loaderService.showPreloader();
    this.loading.next({
      loading: true,
      hasError: false,
      hasMsg: '',
    });
  }

  /**
   * Response interceptor.
   */
  private responseInterceptor(): void {
    // this.loaderService.hidePreloader();
  }

  /**
   * Error handler.
   * @param error
   * @param caught
   * @returns {ErrorObservable}
   */
  private onCatch(error: any, caught: Observable<any>): Observable<any> {
    // this.loaderService.popError();
    return throwError(() => error); // Observable.of(error);
  }

  /**
   * onSubscribeSuccess
   * @param res
   */
  private onSubscribeSuccess(res: HttpResponse<any>): void {
    this.loading.next({
      loading: false,
      hasError: false,
      hasMsg: '',
    });
  }

  /**
   * onSubscribeError
   * @param error
   */
  private onSubscribeError(error: any): void {
    // this.loaderService.popError();
    this.loading.next({
      loading: false,
      hasError: true,
      hasMsg: 'Something went wrong',
    });
  }

  /**
   * onFinally
   */
  private onFinally(): void {
    this.responseInterceptor();
  }

  /**
   * Refresh token Handler
   * @param refreshToken
   */
  getToken(refreshToken: string, options?): any {
    // call to get token
    const refreshTokenUrl = 'url';
    const body = {};
    try {
      return this.post(refreshTokenUrl, body, this.requestOptions(options)).pipe(
        map((response: HttpResponse<any>) => {
          const data: any = response;
          if (data.access_token) {
            // set session storage here;
            this.sessionStorageService.setItem('authHeader', data.authHeader);
            this.sessionStorageService.setItem('refreshToken', data.refreshToken);
            return true;
          }
          return false;
        }),
      );
    } catch (e) {}
  }

  /**
   * Check has query param
   * @param url
   */
  hasQureyString(url: any): any {
    if (url.indexOf('?') !== -1 || url.indexOf('&') !== -1) {
      return true;
    }
    return false;
  }

  private addAuthorization() {
    if (this.platformObj.isBrowser) {
      const access_token = this.sessionStorageService.getItem('authHeader');
      if (access_token) {
        return { authorization: access_token };
      }
    }
  }
}
