<hos-success-alert life="2000"></hos-success-alert>
<hos-my-account-new-header
  [pageName]="true"
  [myProfilePage]="isMyProfile"
  [cusNo]="selectedCustNo"
  (myProfileHomeClick)="pageSwitch()"
  (addressHandler)="addressHandler($event)"
  [firstName]="userProfile?.firstName"
  [lastName]="userProfile?.lastName"
></hos-my-account-new-header>
<section class="content-section content-block profile-section">
  <div class="row page-wrap no-gutters" *ngIf="!!userProfile">
    <div class="col-12 col-md-8">
      <!--  <div class="card custom-card">
                <h5 class="card-header">General information</h5>
                <div class="card-body">
                    <form #generalForm="ngForm" (ngSubmit)="updateProfile(generalForm)" novalidate>
                        <div class="row">
                            <div class="form-group col-12 col-md-6">
                                <label for="selectType" class="light-text">First Name</label>
                                <input type="text" class="form-control round-form" [ngClass]="{'is-invalid':enableGeneral && !firstName.valid}" name="firstName"
                                    required [(ngModel)]="userProfile.firstName" #firstName="ngModel" [disabled]="!enableGeneral">
                                <small id="emailHelp" class="form-text text-danger" *ngIf="enableGeneral && !firstName.valid">First name is required.</small>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="selectType" class="light-text">Last Name</label>
                                <input type="text" class="form-control round-form" [ngClass]="{'is-invalid':enableGeneral && !lastName.valid}" name="lastName"
                                    required [(ngModel)]="userProfile.lastName" #lastName="ngModel" [disabled]="!enableGeneral">
                                <small id="emailHelp" class="form-text text-danger" *ngIf="enableGeneral && !lastName.valid">Last name is required.</small>
                            </div>

                            <div class="form-group col-12 col-md-6">
                                <label for="selectType" class="light-text">Middle Name (Optional)</label>
                                <input type="text" class="form-control round-form" name="middleName" [(ngModel)]="userProfile.middleName" #middleName="ngModel"
                                    [disabled]="!enableGeneral">
                            </div>
                            <div class="row justify-content-end col-12 pr-1" *ngIf="enableGeneral">
                                <div class="app-outline-btn pt-2 mr-5">
                                    <a class="non-href-links link-href" (click)="enableGeneral = false; showGeneralError = false;">CANCEL</a>
                                </div>
                                <button type="submit" [disabled]="!generalForm.form.valid" class="btn btn-primary save-btn m-b">SAVE</button>
                            </div>
                            <div *ngIf="showGeneralError" class="col-12 text-right text-danger pt-2 pr-4">
                                <i class="fa fa-exclamation-triangle error"></i> Sorry, there was a problem updating your profile. Please try again.</div>
                        </div>
                    </form>
                </div>
            </div> -->

      <div class="card custom-card mt-4">
        <h5 class="card-header">
          Contact information
          <span class="float-right" [hidden]="enableContactEdit">
            <a (click)="enableContactEdit = true">
              <i class="fa fa-pencil" aria-hidden="true"></i>
              <span class="edit-txt">EDIT</span>
            </a>
          </span>
        </h5>
        <div class="card-body">
          <form #contactInfoForm="ngForm" (ngSubmit)="updateContactInfo(contactInfoForm)" class="mb-4" novalidate>
            <div class="row">
              <div class="form-group col-12 col-md-6">
                <label for="selectType" class="light-text">Email (It will be used as your Account ID)</label>
                <input
                  type="email"
                  class="form-control round-form"
                  (keypress)="keyPressHandler(email)"
                  (blur)="emailBlurHandler(email)"
                  [ngClass]="{ 'is-invalid': enableContactEdit && !!email.isBlur && !email.valid }"
                  [pattern]="email_regex"
                  name="email"
                  required
                  [(ngModel)]="userProfile.email"
                  #email="ngModel"
                  [disabled]="!enableContactEdit"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="enableContactEdit && email.isBlur && !email.valid && email.dirty"
                  >Please Enter valid email address.</small
                >

                <small
                  id="emailHelp"
                  class="form-text text-danger"
                  *ngIf="(enableContactEdit && !email.valid && !email.touched && !email.dirty) || isDisposableEmail || isDisposableEmailPrimary"
                  >Please Enter valid email address.</small
                >
                <small id="emailHelp" *ngIf="showEmailError" class="form-text text-danger">{{ emailerror }}</small>
              </div>
              <div class="form-group col-12 col-md-6">
                <label for="selectType" class="light-text">Secondary Email (Optional)</label>
                <input
                  type="email"
                  [disabled]="!enableContactEdit"
                  class="form-control round-form"
                  (keypress)="keyPressHandler(email2)"
                  (blur)="emailBlurHandler(email2)"
                  [ngClass]="{ 'is-invalid': enableContactEdit && !!email2.isBlur && !email2.valid }"
                  [pattern]="email_regex"
                  name="email2"
                  [(ngModel)]="userProfile.email2"
                  #email2="ngModel"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="enableContactEdit && email2.isBlur && !email2.valid && email2.dirty"
                  >Please Enter valid email address.</small
                >

                <small
                  id="emailHelp"
                  class="form-text text-danger"
                  *ngIf="(enableContactEdit && !email2.valid && !email2.touched && !email2.dirty) || isDisposableEmail || isDisposableEmailSecondary"
                  >Please Enter valid email address.</small
                >
              </div>
              <div class="form-group col-12 col-md-6">
                <label for="selectType" class="light-text">Home Phone Number</label>
                <input
                  type="text"
                  class="form-control round-form"
                  (keypress)="keyPressHandler(phoneNumber); numberOnly($event)"
                  (blur)="phoneBlurHandler(phoneNumber)"
                  [ngClass]="{ 'is-invalid': enableContactEdit && phoneNumber.isBlur && !phoneNumber.valid }"
                  (input)="phoneChangeHandler()"
                  maxlength="10"
                  minlength="10"
                  [pattern]="phone_number_regex"
                  name="phoneNumber"
                  required
                  [(ngModel)]="userProfile.phoneNumber"
                  #phoneNumber="ngModel"
                  [disabled]="!enableContactEdit"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="enableContactEdit && !homePhoneErrorMsg && !phoneNumber.valid"
                  >Please enter valid phone number.</small
                >
                <small id="emailHelp" class="form-text text-danger" *ngIf="enableContactEdit">{{ homePhoneErrorMsg }}</small>
                <!-- <small id="emailHelp" *ngIf="showPhoneError" class="form-text text-danger">{{phoneerror}}</small> -->
              </div>
              <!-- [VJ HWR-2661-2654 MOBILE FIELD RELATED CODE] -->
              <div class="form-group col-12 col-md-6">
                <label for="selectType" class="light-text">Mobile Phone Number (Optional)</label>
                <input
                  type="text"
                  [disabled]="!enableContactEdit"
                  class="form-control round-form"
                  (blur)="blurHandler(mobilePhoneNumber)"
                  (keypress)="keyPressHandler(mobilePhoneNumber); numberOnly($event)"
                  (input)="phoneChangeHandler()"
                  maxlength="10"
                  minlength="10"
                  [pattern]="phone_number_regex"
                  [ngClass]="{ 'is-invalid': enableContactEdit && !mobilePhoneNumber.valid }"
                  name="mobilePhoneNumber"
                  [(ngModel)]="userProfile.mobilePhoneNumber"
                  #mobilePhoneNumber="ngModel"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="enableContactEdit && !mobilePhoneErrorMsg && !mobilePhoneNumber.valid"
                  >Please enter valid phone number.</small
                >
                <small id="emailHelp" class="form-text text-danger" *ngIf="enableContactEdit">{{ mobilePhoneErrorMsg }}</small>
              </div>
              <div class="form-group col-12 col-md-12" *ngIf="enableContactEdit">
                <label class="custom-control custom-checkbox checkbox-tick-check">
                  <input
                    type="checkbox"
                    name="isAuthorizedToSendMessage"
                    [(ngModel)]="userProfile.isAuthorizedToSendMessage"
                    #isAuthorizedToSendMessage="ngModel"
                    class="custom-control-input"
                  />
                  <span class="custom-control-indicator"></span>
                  <span class="custom-control-description"
                    >Yes, I authorize <span class="skiptranslate">American Water Resources</span> to send me text messages.</span
                  >
                </label>
              </div>
              <div class="form-group col-12 col-md-12" *ngIf="!enableContactEdit && userProfile.isAuthorizedToSendMessage">
                <span class="custom-control-description"
                  >Yes, I authorize <span class="skiptranslate">American Water Resources</span> to send me text messages.</span
                >
              </div>
              <div class="row justify-content-end col-12 pr-1" *ngIf="enableContactEdit">
                <div class="pt-2 mr-5">
                  <a
                    class="non-href-links link-href"
                    (click)="enableContactEdit = false; showContactError = false; showEmailError = false; setoldform()"
                    >CANCEL</a
                  >
                </div>
                <button type="submit" class="btn btn-primary save-btn m-b" [disabled]="disabledSave">SAVE</button>
              </div>
              <div *ngIf="showContactError" class="col-12 text-right text-danger pt-2 pr-4">
                <i class="fa fa-exclamation-triangle error"></i> Sorry, there was a problem updating your profile. Please try again.
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card custom-card mt-4">
        <h5 class="card-header">
          Log In information
        </h5>
        <div class="card-body">
          <form #credentialForm="ngForm" (ngSubmit)="updateLogInInfo(credentialForm); isView = false; isConfirmView = false" class="mb-4" novalidate>
            <div class="row">
              <div class="form-group col-12">
                <label for="selectType" class="light-text">Customer ID</label>
                <div>
                  <div class="row">
                    <div class="col-12 col-md-9 col-lg-8">
                      <div class="input-badge mb-2">
                        <input
                          *ngIf="userProfile.email"
                          disabled="true"
                          type="email"
                          class="form-control round-form"
                          name="loginId"
                          required
                          [(ngModel)]="userProfile.email"
                          #loginId="ngModel"
                        />
                        <span class="badge badge-success" *ngIf="isEmailVerified">Verified</span>
                        <span class="badge badge-secondary" *ngIf="!isEmailVerified">Not Verified</span>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 col-lg-4">
                      <div *ngIf="!isEmailVerified">
                        <button class="btn btn-primary verify-btn" type="button" (click)="onVerifyClick()">
                          <div class="spinner" *ngIf="verifyEmailBtn.state === 'loading'">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                          </div>
                          {{ verifyEmailBtn.text }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group col-12 col-md-9 col-lg-8" [hidden]="enableCredentialEdit">
                <label for="selectType" class="light-text">Password</label>
                <input disabled="true" type="password" class="form-control round-form" name="pass" value="*********" />
              </div>
              <div class="form-group col-12 col-md-6" [hidden]="!enableCredentialEdit">
                <label for="selectType" class="light-text">Password</label>
                <input
                  [type]="isView ? 'text' : 'password'"
                  class="form-control round-form"
                  (blur)="blurHandler(password)"
                  [ngClass]="{ 'is-invalid': enableCredentialEdit && password.isBlur && !password.valid && (password.dirty || password.touched) }"
                  [pattern]="password_regex"
                  name="password"
                  ngModel
                  #password="ngModel"
                />
                <span (click)="isView = !isView" class="fa {{ isView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
                <br />
                <label for="selectType" class="light-text">Confirm Password</label>
                <input
                  [type]="isConfirmView ? 'text' : 'password'"
                  class="form-control round-form"
                  (blur)="blurHandler(confirmPassword)"
                  [ngClass]="{ 'is-invalid': confirmPassword.isBlur && !confirmPassword.valid }"
                  [pattern]="password_regex"
                  name="confirmPassword"
                  ngModel
                  #confirmPassword="ngModel"
                />
                <span (click)="isConfirmView = !isConfirmView" class="fa {{ isConfirmView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
              </div>
              <div class="form-group col-12 col-md-6" [hidden]="!enableCredentialEdit">
                <hos-password-strength-meter
                  [password]="password.value"
                  [confirmPassword]="confirmPassword.value"
                  (passwordMeterResult)="getPasswordMeterResult($event)"
                ></hos-password-strength-meter>
              </div>
            </div>
            <div class="row">
              <div class="row justify-content-end col-12 pr-1" *ngIf="enableCredentialEdit">
                <div class="pt-2 mr-5">
                  <a
                    class="non-href-links link-href"
                    (click)="
                      showChangePasswordError = false;
                      clearcredentials(credentialForm);
                      showChangeSecurityError = false;
                      setoldsecurityform(securityqueForm);
                      enableSecurityEdit = false;
                      isClicked = false;
                      passwordStrength = 'initial';
                      isView = false;
                      isConfirmView = false;
                      isViewSecurity = [false, false]
                    "
                    >CANCEL</a
                  >
                </div>
                <button type="submit" class="btn btn-primary save-btn m-b">SAVE</button>
              </div>
              <div *ngIf="showChangePasswordError" class="col-12 text-right text-danger pt-2 pr-4">
                <i class="fa fa-exclamation-triangle error"></i> Sorry, there was a problem updating your profile. Please try again.
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="card custom-card mt-4">
        <h5 class="card-header">Service Address(es)</h5>
        <div class="card-body">
          <form #addressForm="ngForm" (ngSubmit)="updateAddress(addressForm)" novalidate>
            <div class="row" *ngIf="!enableEditAddress">
              <div class="col-12 col-md-6 mb-2" *ngFor="let address of userProfile.address; let index = index">
                <div class="address-block">
                  <div class="service-adderess-block row no-gutters">
                    <span class="col-2 col-sm-3 col-md-3 col-lg-2">
                      <img
                        *ngIf="address?.serviceAddress?.contactType === 'RESIDENTIAL' || address?.serviceAddress?.contactType !== 'COMMERCIAL'"
                        src="img/my-account/icons/Residential-Ico.svg"
                        alt="Residential Icon"
                      />
                      <img
                        *ngIf="address?.serviceAddress?.contactType === 'COMMERCIAL'"
                        src="img/my-account/icons/Commercial-Ico.svg"
                        alt="Commercial Icon"
                      />
                    </span>
                    <span class="col-10 col-sm-9 col-md-9 col-lg-10 skiptranslate">
                      {{ address.serviceAddress.streetNumber }} {{ address.serviceAddress.streetName }}, <br />
                      {{ address.serviceAddress.city }}, {{ address.serviceAddress.state }}, {{ address.serviceAddress.zipcode }}
                    </span>
                  </div>
                  <div class="billing-adderess-block">
                    <span class="billing-title">Billing Address</span>
                    <p class="billing-add-txt skiptranslate">
                      {{ address.billingAddress.streetNumber }} {{ address.billingAddress.streetName }}, {{ address.billingAddress.city }},
                      {{ address.billingAddress.state }}, {{ address.billingAddress.zipcode }}
                    </p>
                    <div class="edit-remove-links">
                      <a (click)="updateServiceAddress(address)" class="link-href">EDIT</a>
                      <a (click)="deleteServiceAddress(address)" class="link-href-remove">REMOVE PROPERTY</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="head-title-text col-12 col-md-8 hidden">
      <div class="information">
        <!-- <div class="account-preference ">
                    <form role="form" [formGroup]="preferenceForm" novalidate>
                        <div class="row">
                            <h4 class="contact-head detail-head">Account Preferences</h4>
                            <div class="to-right vertical-center non-href-links mr-3" [hidden]="enablePreferenceEdit">
                                <a (click)="enablePreferenceEdit=true;">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 row">
                            <div class="col-md-5 col-8 light-text">
                                <i class="fa fa-envelope icons" aria-hidden="true"></i> &nbsp; Message Notification</div>
                            <div class="col-1 minHeight50">
                                <span [hidden]="preferenceForm.value.message" class="pt-1">OFF &nbsp;</span>
                                <span [hidden]="!preferenceForm.value.message" *ngIf="!enablePreferenceEdit" class="pt-1">ON &nbsp;</span>
                            </div>
                            <label class="switch" *ngIf="enablePreferenceEdit">
                                <input type="checkbox" name="message" formControlName="message">
                                <span class="slider round"></span>
                            </label>
                            <div class="col-1 minHeight50">
                                <span [hidden]="!preferenceForm.value.message" *ngIf="enablePreferenceEdit" class="pt-1">&nbsp; ON</span>
                            </div>
                        </div>
                        <div class="col-12 row">
                            <div class="col-md-5 col-8 light-text">
                                <i class="fa fa-bell icons" aria-hidden="true"></i> &nbsp; Alert Notification</div>
                            <div class="col-1 minHeight50">
                                <span [hidden]="preferenceForm.value.alert" class="pt-1">OFF &nbsp;</span>
                                <span [hidden]="!preferenceForm.value.alert" *ngIf="!enablePreferenceEdit" class="pt-1">ON &nbsp;</span>
                            </div>
                            <label class="switch" *ngIf="enablePreferenceEdit">
                                <input type="checkbox" name="alert" formControlName="alert">
                                <span class="slider round"></span>
                            </label>
                            <div class="col-1 minHeight50">
                                <span [hidden]="!preferenceForm.value.alert" *ngIf="enablePreferenceEdit" class="pt-1">&nbsp; ON</span>
                            </div>
                        </div>
                        <div class="col-12 row">
                            <div class="col-md-5 col-8 light-text pl-5">
                                <i class="fa fa-file-text icons width25" aria-hidden="true"></i> &nbsp; Text Notification</div>
                            <div class="col-1 minHeight50">
                                <span [hidden]="preferenceForm.value.text" class="pt-1">OFF &nbsp;</span>
                                <span [hidden]="!preferenceForm.value.text" *ngIf="!enablePreferenceEdit" class="pt-1">ON &nbsp;</span>
                            </div>
                            <label class="switch" *ngIf="enablePreferenceEdit">
                                <input type="checkbox" name="text" formControlName="text">
                                <span class="slider round"></span>
                            </label>
                            <div class="col-1 minHeight50">
                                <span [hidden]="!preferenceForm.value.text" *ngIf="enablePreferenceEdit" class="pt-1">&nbsp; ON</span>
                            </div>
                        </div>
                        <div class="col-12 row">
                            <div class="col-md-5 col-8 light-text pl-5">
                                <i class="fa fa-envelope icons width25" aria-hidden="true"></i> &nbsp; Mail Notification</div>
                            <div class="col-1 minHeight50">
                                <span [hidden]="preferenceForm.value.mail" class="pt-1">OFF &nbsp;</span>
                                <span [hidden]="!preferenceForm.value.mail" *ngIf="!enablePreferenceEdit" class="pt-1">ON &nbsp;</span>
                            </div>
                            <label class="switch" *ngIf="enablePreferenceEdit">
                                <input type="checkbox" name="mail" formControlName="mail">
                                <span class="slider round"></span>
                            </label>
                            <div class="col-1 minHeight50">
                                <span [hidden]="!preferenceForm.value.mail" *ngIf="enablePreferenceEdit" class="pt-1">&nbsp; ON</span>
                            </div>
                        </div>
                        <div class="col-12 row paddT5 mobIcon">
                            <div class="col-md-5 col-8 light-text pl-5">
                                <i class="fa fa-mobile icons width25 fontSize35" aria-hidden="true"></i><span> &nbsp; Mobile Notification</span></div>
                            <div class="col-1 minHeight50 marT10 vAlignM">
                                <span [hidden]="preferenceForm.value.mobile" class="pt-1">OFF &nbsp;</span>
                                <span [hidden]="!preferenceForm.value.mobile" *ngIf="!enablePreferenceEdit" class="pt-1">ON &nbsp;</span>
                            </div>
                            <label class="switch marT10 vAlignM" *ngIf="enablePreferenceEdit">
                                <input type="checkbox" name="mobile" formControlName="mobile">
                                <span class="slider round"></span>
                            </label>
                            <div class="col-1 minHeight50 vAlignM">
                                <span [hidden]="!preferenceForm.value.mobile" *ngIf="enablePreferenceEdit" class="pt-1">&nbsp; ON</span>
                            </div>
                        </div>
                        <div class="row justify-content-end col-12 pr-1" *ngIf="enablePreferenceEdit">
                            <div class="app-outline-btn pt-1 mr-5">
                                <a class="non-href-links" (click)="getUserProfile();enablePreferenceEdit=false;"> Cancel</a>
                            </div>
                            <button type="submit" (click)="updatePreferences();" class="btn btn-primary block full-width app-btn m-b">Save </button>
                        </div>
                    </form>
                </div> -->
      </div>
    </div>
    <div class="col-12 col-md-3 tips-box">
      <h4 class="detail-head">{{ ProfileTipsTitle }}</h4>
      <div class="ml-4">
        <p class="text-info" *ngFor="let tip of ProfileTipsTips">
          {{ tip.value }}
        </p>
        <!-- <p class="hidden light-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                    dolore magna aliquam erat volutpat.</p>
                <p>Have a plumber inspect your installations for leaks</p>
                <p>Don't shower longer than necessary</p>
                <p>Don't fill the bathtub all the way up</p>
                <p>Turn off the water while brushing your teeth</p>
                <p>Use a bucket to wash your car</p> -->
      </div>
    </div>
  </div>
</section>
<div [ngClass]="{ loading: !!showLoader }"></div>
